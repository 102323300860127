import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Up } from "@src/components/molecules/fadein/index"

/**
 * メインステージ, ポリシーセクション
 */
const MainStage = () => {
    return(
        <section className="page-section page-section-image">
            <div className="gray-filter">
                <StaticImage style={{ width: `100%`, maxHeight: `700px`}} imgStyle={{objectPosition: `50% 35%`}} src="../../../images/main-stage.jpg" alt="メインステージへ導きます" />
                <div className="gray-filter-item l-contents-padding">
                    <Up>
                        <h3 style={{color: `white`}} className="page-section-title text-center main-stage-text">メインステージへ導きます</h3>
                    </Up>
                </div>
            </div>
        </section>
    )
}

export default MainStage