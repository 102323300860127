import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";

import ContactInfo from "@src/components/molecules/contact-info/index";
import MainStage from "@src/components/molecules/main-stage/index";
import PageDescription from "@src/components/molecules/page-description/index";
import PageHeader from "@src/components/molecules/page-header/index";

import { Up } from "@src/components/molecules/fadein/index";
import { ContactLink, CreationLink, MarketingLink, InnovationLink } from "@src/components/atoms/links/index";

import Poster from "@src/images/video-poster.jpg"

import "./style.css";

const Home = () => {
    /**
     * @type {React.MutableRefObject<HTMLVideoElement>}
     */
    const ref = React.useRef(null);
    React.useEffect(() => {
        // ランダムに2種類の動画を再生
        const random = Math.random() < 0.45;
        ref.current.firstChild.src = random ? "https://dl5km2ob7ccxv.cloudfront.net/main/key-visual-black.mp4" : "https://dl5km2ob7ccxv.cloudfront.net/main/key-visual-white.mp4";
        ref.current.load();
    }, [])

    return(
        <article className="home">
            <PageHeader title="HOME">
                <video autoPlay loop muted playsInline poster={Poster} style={{ width: "100%"}} ref={ref}>
                    <source src="" />
                    <p>動画の再生に対応していません</p>
                </video>
            </PageHeader>

            <PageDescription title='株式会社メインへようこそ。'>
                <Up>
                    <div>
                        <p>愛知県豊川市にて創造事業(デザイン)&革命事業(システム開発)を営む会社です。</p>
                        <p>これから何かを始める人、この先の事業展開についてお悩みの方</p>
                        <p>私たちと共に創造革命を起こしませんか？お問い合わせはこちらから。</p>
                    </div>
                    <ContactLink className="button">お問い合わせへ</ContactLink>
                </Up>
            </PageDescription>

            <section className="page-section page-section-background policy">
                <div className="l-contents-padding">
                    <Up>
                        <h3 className="page-section-title text-white text-center">創造革命とは</h3>
                    </Up>
                    <Up>
                        <div className="l-max-width">
                            <p>創造(creative)お客様のお考えになる想いや意向をデザインという形あるものに表現します。</p>
                            <p>革命(innovation)お客様のお考えになる新たな価値ある事業や想いを再現したシステム開発を行います。</p>
                            <p>創造力(creative)+革命力(innovation)でお客様をメインステージ(MAIN Stage)へと導く事が創造革命ということです。</p>
                        </div>
                    </Up>
                </div>
            </section>

            <MainStage />



            <section className="page-section service">
                <div className="service-image">
                    <Up>
                        <StaticImage className="service-image-wrap" src="../../../images/creation/key-visual.jpg" alt="創造事業部イメージ" />
                    </Up>
                </div>
                <div className="l-contents-padding service-description">
                    <Up>
                        <h3 className="page-section-title service-title">創造事業部</h3>
                        <h5 className="service-title-sub">creation</h5>
                        <p>お客様のお考えになる想いや意向をデザインという形あるものに表現します。</p>
                        <CreationLink className="button">もっと見る</CreationLink>
                    </Up>
                </div>
            </section>

            <section className="page-section service">
                <div className="service-image">
                    <Up>
                        <StaticImage className="service-image-wrap" src="../../../images/innovation/key-visual.jpg" alt="革命事業部イメージ" />
                    </Up>
                </div>
                <div className="l-contents-padding service-description">
                    <Up>
                        <h3 className="page-section-title service-title">革命事業部</h3>
                        <h5 className="service-title-sub">innovation</h5>
                        <p>お客様のお考えになる新たな価値ある事業や想いを再現したシステム開発を行います。</p>
                        <InnovationLink className="button" >もっと見る</InnovationLink>
                    </Up>
                </div>
            </section>

            <section className="page-section service">
                <div className="service-image">
                    <Up>
                        <StaticImage className="service-image-wrap" src="../../../images/marketing/key-visual.jpg" alt="運用事業部イメージ" />
                    </Up>
                </div>
                <div className="l-contents-padding service-description">
                    <Up>
                        <h3 className="page-section-title service-title">運用事業部</h3>
                        <h5 className="service-title-sub">marketing</h5>
                        <p>運用事業部です。</p>
                        <MarketingLink className="button" >もっと見る</MarketingLink>
                    </Up>
                </div>
            </section>
            
            <ContactInfo />
        </article>
    )
}

export default Home;