import * as React from "react";

import "./style.css";

/**
 * ページの説明セクション
 */
const PageDescription = ({title, children}) => {
    return(
    <section className="page-section page-description">
        <div className="l-contents-padding">
            <div className="l-contents-padding-md page-description-wrap">
                <div>
                    <h4 className="page-section-title">{title}</h4>
                </div>
                <div className="page-description-text">{children}</div>
            </div>
        </div>
    </section>
    )
}

export default PageDescription